import { createSlice } from '@reduxjs/toolkit'

interface WorkingGamesFilterState {
    isOnlyWorkingGamesOnEnvsPage: boolean
    isOnlyWorkingGamesOnLiveMonitoring: boolean
}

const initialState: WorkingGamesFilterState = {
    isOnlyWorkingGamesOnEnvsPage: false,
    isOnlyWorkingGamesOnLiveMonitoring: false,
}

const toggle = (currentValue: boolean) => !currentValue

const workingGamesFilterSlice = createSlice({
    name: 'workingGamesFilter',
    initialState,
    reducers: {
        toggleEnvsPageWorkingGames: (state) => {
            state.isOnlyWorkingGamesOnEnvsPage = toggle(state.isOnlyWorkingGamesOnEnvsPage)
        },
        toggleLiveMonitoringWorkingGames: (state) => {
            state.isOnlyWorkingGamesOnLiveMonitoring = toggle(state.isOnlyWorkingGamesOnLiveMonitoring)
        },
    },
})

export const { toggleEnvsPageWorkingGames, toggleLiveMonitoringWorkingGames } = workingGamesFilterSlice.actions

export default workingGamesFilterSlice.reducer
