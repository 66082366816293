import { useEffect } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { RequireAuth } from 'components/hoc/RequireAuth'
import { MainPage } from 'components/pages/mainPage/MainPage'
import AdminPageContainer from 'components/pages/adminPage'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { Preloader } from 'components/Preloader/Preloader'
import { updateUserAuth } from 'store/slices/userAuthSlice'
import { fetchUserBalance, setAllowedEnvironments } from 'store/slices/userDataSlice'
import LoginPage from 'components/pages/loginPage/LoginPage/LoginPage'
import { MainLayout } from 'components/mainLayout/MainLayout'
import EnvironmentPage from 'components/pages/environmentPage/EnvironmentPage'
import { PermissionTitle } from 'types/user'
import { getAllowedEnvironments, getInitEnvironment, userHasAccessToEnvironment } from 'utils/userUtils'
import { setEnvironment } from 'store/slices/configUISlice'
import { Page } from 'types/common'
import { ENVIRONMENT, ROUTE } from 'consts/constants'
import LiveEnvMonitoringPage from 'components/pages/liveEnvMonitoringPage/LiveEnvMonitoringPage'

function App() {
    const user = useAppSelector((state) => state.user.info)
    const { environment } = useAppSelector((state) => state.stateUI)
    const isLoading = useAppSelector((state) => state.userAuth.isLoading)
    const dispatch = useAppDispatch()

    const token = localStorage.getItem('token')

    useEffect(() => {
        if (!user) return

        const { permissions } = user.role
        const allowedEnvironments = getAllowedEnvironments(permissions, Page.Main)

        const userHasAccessToSettedEnvironment = userHasAccessToEnvironment(permissions, environment)

        if (!userHasAccessToSettedEnvironment || environment === ENVIRONMENT.LIVE) {
            const newEnvironment = getInitEnvironment(allowedEnvironments)
            dispatch(setEnvironment(newEnvironment))
        }

        dispatch(setAllowedEnvironments(allowedEnvironments))
    }, [dispatch, environment, user])

    useEffect(() => {
        if (token) {
            dispatch(updateUserAuth())
            dispatch(fetchUserBalance())
        }
    }, [token, dispatch])

    if (token && isLoading) {
        return <Preloader />
    }

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<MainLayout />}>
                        <Route path="login" element={<LoginPage />} />
                        <Route
                            element={
                                <RequireAuth
                                    requiredPermissions={[
                                        PermissionTitle.DevGameAccess,
                                        PermissionTitle.StageGameAccess,
                                        PermissionTitle.CertGameAccess,
                                    ]}
                                />
                            }
                        >
                            <Route index element={<MainPage />} />
                        </Route>

                        <Route element={<RequireAuth requiredPermissions={[PermissionTitle.UserManagement]} />}>
                            <Route path="admin" element={<AdminPageContainer />} />
                        </Route>

                        <Route element={<RequireAuth requiredPermissions={[PermissionTitle.EnvsPageAccess]} />}>
                            <Route path="environments" element={<EnvironmentPage />} />
                            <Route path="liveEnvMonitoring" element={<LiveEnvMonitoringPage />} />
                        </Route>

                        <Route path="*" element={<Navigate to={ROUTE.MAIN} replace />} />
                    </Route>
                </Routes>
            </Router>
        </>
    )
}

export default App
