import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { fetchUsers } from 'store/slices/usersSlice'
import { showSnackbar } from 'store/slices/snackbarSlice'
import UsersTable from 'components/pages/adminPage/editUsers/UsersTable/UsersTable'
import UserForm from 'components/pages/adminPage/editUsers/UserForm.js/UserForm'
import CreditForm from 'components/CreditForm'
import { UserRemoveDialog } from 'components/pages/adminPage/editUsers/UserRemoveDialog'
import { User } from 'types/user'
import PasswordResetForm from 'components/pages/adminPage/editUsers/PasswordResetForm'
import { UserFormType } from 'types/common'
import AdministrationUserService from 'services/AdministrationUserService'
import { Preloader } from 'components/Preloader/Preloader'

export const EditUsersContainer = () => {
    const dispatch = useAppDispatch()

    const { users, isLoading } = useAppSelector((state) => state.users)
    // Can't reach this code without user info
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentUser = useAppSelector((state) => state.user.info!)
    const role = useAppSelector((state) => state.user.info?.role.title)
    const currency = useAppSelector((state) => state.currencySettings.currency)
    const currencyIcon = useAppSelector((state) => state.currencySettings.currencyIcon)

    const [isOpenUserForm, setIsOpenUserForm] = useState<boolean>(false)
    const [isOpenUserRemoveDialog, setIsOpenUserRemoveDialog] = useState<boolean>(false)
    const [isOpenCreditForm, setIsOpenCreditForm] = useState<boolean>(false)
    const [isOpenPasswordForm, setIsOpenPasswordForm] = useState<boolean>(false)
    const [titleUserForm, setTitleUserForm] = useState<string>('')
    const [userFormType, setUserFormType] = useState<UserFormType>(UserFormType.AddUser)
    const [creditFormBalanceFun, setCreditFormBalanceFun] = useState<number>(0)
    const [creditFormBalanceReal, setCreditFormBalanceReal] = useState<number>(0)
    const [userId, setUserId] = useState<number>()
    const [selectedUser, setSelectedUser] = useState<User>({} as User)

    const isAdmin = role === 'admin'

    useEffect(() => {
        dispatch(fetchUsers())
    }, [dispatch])

    const handleClickRemoveUser = (user: User) => {
        setIsOpenUserRemoveDialog(true)
        setSelectedUser(user)
    }

    const removeUser = async (id: number) => {
        setIsOpenUserRemoveDialog(false)
        try {
            await AdministrationUserService.removeUser(id)
            dispatch(fetchUsers())
            dispatch(showSnackbar({ message: 'User successfully removed', severity: 'success' }))
        } catch (error) {
            const errorMessage = (error as Error).message
            dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
            console.error(errorMessage)
        }
    }

    const changeUser = async (nickname: string) => {
        const userToEdit = isAdmin ? users.find((user) => user.nickname === nickname) || currentUser : currentUser
        setSelectedUser(userToEdit)
        setTitleUserForm('Edit user')
        setUserFormType(UserFormType.ChangeUser)
        setIsOpenUserForm(true)
    }

    const changePassword = async (id: number) => {
        const userToEdit = isAdmin ? users.find((user) => user.id === id) || currentUser : currentUser
        setSelectedUser(userToEdit)
        setIsOpenPasswordForm(true)
    }

    const openUserForm = () => {
        setSelectedUser({} as User)
        setTitleUserForm('Add new user')
        setUserFormType(UserFormType.AddUser)
        setIsOpenUserForm(true)
    }

    const handleClickOpenCreditForm = async ({
        realBalance,
        funBalance,
        userId,
    }: {
        realBalance: number
        funBalance: number
        userId: number
    }) => {
        setIsOpenCreditForm(true)
        setCreditFormBalanceReal(realBalance)
        setCreditFormBalanceFun(funBalance)
        setUserId(userId)
    }

    return isLoading ? (
        <Preloader />
    ) : (
        <>
            {isAdmin ? (
                <UsersTable
                    users={users}
                    handleClickOpenCreditForm={handleClickOpenCreditForm}
                    handleClickRemoveUser={handleClickRemoveUser}
                    changeUser={changeUser}
                    changePassword={changePassword}
                    currencyIcon={currencyIcon}
                    currency={currency}
                    openUserForm={openUserForm}
                    isAdmin={isAdmin}
                ></UsersTable>
            ) : (
                <UsersTable
                    users={[currentUser]}
                    handleClickOpenCreditForm={handleClickOpenCreditForm}
                    handleClickRemoveUser={handleClickRemoveUser}
                    changeUser={changeUser}
                    changePassword={changePassword}
                    currencyIcon={currencyIcon}
                    currency={currency}
                    openUserForm={openUserForm}
                    isAdmin={isAdmin}
                ></UsersTable>
            )}
            <CreditForm
                userAPIType="RequestedUser"
                isOpenCreditForm={isOpenCreditForm}
                setIsOpenCreditForm={setIsOpenCreditForm}
                realBalanceInCurrency={creditFormBalanceReal}
                funBalanceInCurrency={creditFormBalanceFun}
                userId={userId}
                currency={currency}
                asyncAction={() => dispatch(fetchUsers())}
            ></CreditForm>
            <UserForm
                user={selectedUser}
                isAdmin={isAdmin}
                userFormType={userFormType}
                titleUserForm={titleUserForm}
                isOpenUserForm={isOpenUserForm}
                setIsOpenUserForm={setIsOpenUserForm}
            ></UserForm>
            <PasswordResetForm
                user={selectedUser}
                isOpenPasswordForm={isOpenPasswordForm}
                setIsOpenPasswordForm={setIsOpenPasswordForm}
            ></PasswordResetForm>
            <UserRemoveDialog
                selectedUser={selectedUser}
                isOpenUserRemoveDialog={isOpenUserRemoveDialog}
                setIsOpenUserRemoveDialog={setIsOpenUserRemoveDialog}
                removeUser={removeUser}
            ></UserRemoveDialog>
        </>
    )
}
