import { Preloader } from 'components/Preloader/Preloader'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import RolesTable from './RolesTable/RolesTable'
import { RoleInfo } from 'types/user'
import { fetchPermissions } from 'store/slices/permissionsSlice'
import RoleForm from './RoleForm/RoleForm'
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog'
import AdministrationRoleService from 'services/AdministrationRoleService'
import { showSnackbar } from 'store/slices/snackbarSlice'

const EditRolesContainer = () => {
    const { roles, isLoading: isRolesLoading } = useAppSelector((state) => state.roles)
    const { permissions, isLoading: isPermissionsLoading } = useAppSelector((state) => state.permissions)

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [selectedRole, setSelectedRole] = useState<RoleInfo>()
    const [isRemoveRoleDialogOpen, setIsRemoveRoleDialogOpen] = useState(false)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchPermissions())
    }, [dispatch])

    const handleFormOpen = (roleId?: number) => {
        const selectedRole = roleId ? roles.find((role) => role.id === roleId) : undefined
        setSelectedRole(selectedRole)
        setIsFormOpen(true)
    }

    const handleFormClose = () => {
        setIsFormOpen(false)
    }

    const handleRemoveRoleDialogOpen = (roleId: number) => {
        const selectedRole = roles.find((role) => role.id === roleId)
        setSelectedRole(selectedRole)
        setIsRemoveRoleDialogOpen(true)
    }

    const handleRemoveRoleDialogClose = () => {
        setIsRemoveRoleDialogOpen(false)
    }

    const removeRole = async (roleId: number) => {
        try {
            await AdministrationRoleService.removeRole(roleId)
            dispatch(showSnackbar({ message: 'Role removed successfully', severity: 'success' }))
            setIsRemoveRoleDialogOpen(false)
        } catch (error) {
            const errorMessage = (error as Error).message
            dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
            console.error(errorMessage)
        }
    }

    return (
        <>
            {isRolesLoading || isPermissionsLoading ? (
                <Preloader />
            ) : (
                <>
                    <RolesTable
                        roles={roles}
                        handleFormOpen={handleFormOpen}
                        handleRemoveRoleDialogOpen={handleRemoveRoleDialogOpen}
                    />
                    <RoleForm
                        isFormOpen={isFormOpen}
                        permissions={permissions}
                        handleClose={handleFormClose}
                    ></RoleForm>
                    <RoleForm
                        isFormOpen={isFormOpen}
                        permissions={permissions}
                        handleClose={handleFormClose}
                        role={selectedRole}
                    ></RoleForm>
                </>
            )}
            {selectedRole && (
                <ConfirmationDialog
                    isOpen={isRemoveRoleDialogOpen}
                    title="Remove role"
                    onConfirm={removeRole.bind(undefined, selectedRole.id)}
                    onClose={handleRemoveRoleDialogClose}
                    description={`Are you sure you want to remove  '${selectedRole?.title}' role`}
                ></ConfirmationDialog>
            )}
        </>
    )
}

export default EditRolesContainer
