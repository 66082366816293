import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { SelectChangeEvent } from '@mui/material'
import EnvironmentSelect from './EnvironmentSelect/EnvironmentSelect'
import GamesTable from './GamesTable/GamesTable'
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'
import { Preloader } from 'components/Preloader/Preloader'
import OnlyWorkingGamesSwitch from 'components/OnlyWorkingGamesSwitch/OnlyWorkingGamesSwitch'
import GameService from 'services/GameService'
import { Game } from 'types/gameService'
import { getAllowedEnvironments, getInitEnvironment } from 'utils/userUtils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { Page } from 'types/common'
import { setCurrentPage } from 'store/slices/currentPageSlice'
import { toggleEnvsPageWorkingGames } from 'store/slices/workingGamesFilterSlice'
import { filterWorkingGames } from 'utils/gameUtils'
import styles from './emvironmentPage.module.scss'

const EnvironmentPage: React.FC = () => {
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user.info)
    const { isOnlyWorkingGamesOnEnvsPage } = useAppSelector((state) => state.workingGamesFilter)

    const [environment, setEnvironment] = useState<string>('')
    const [allowedEnvironments, setAllowedEnvironments] = useState<string[]>([])
    const [games, setGames] = useState<Game[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const handleChangeEnvironment = (event: SelectChangeEvent<string>): void => {
        setEnvironment(event.target.value)
    }

    const handleStatusGame = (): void => {
        dispatch(toggleEnvsPageWorkingGames())
    }

    useEffect(() => {
        dispatch(setCurrentPage(Page.Environments))
    }, [dispatch])

    useEffect(() => {
        if (user) {
            const allowedEnvironments = getAllowedEnvironments(user.role.permissions, Page.Environments)
            setAllowedEnvironments(allowedEnvironments)

            const initEnvironment = getInitEnvironment(allowedEnvironments)
            setEnvironment(initEnvironment)
        }
    }, [user])

    useEffect(() => {
        ;(async function fetchGames() {
            if (!environment) return

            setLoading(true)
            try {
                const response = await GameService.getGames(environment)
                setGames(response.data.games)
            } catch (error) {
                console.error((error as Error).message)
            } finally {
                setLoading(false)
            }
        })()
    }, [environment])

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className={styles.formGroup}>
                <OnlyWorkingGamesSwitch
                    checked={isOnlyWorkingGamesOnEnvsPage}
                    onChange={handleStatusGame}
                    disabled={loading}
                />
                <EnvironmentSelect
                    environment={environment}
                    handleChangeEnvironment={handleChangeEnvironment}
                    environments={allowedEnvironments}
                    disabled={loading}
                />
            </div>
            {loading ? (
                <Preloader />
            ) : (
                <GamesTable games={isOnlyWorkingGamesOnEnvsPage ? filterWorkingGames(games) : games} />
            )}
        </ErrorBoundary>
    )
}

export default EnvironmentPage
