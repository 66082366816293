import React, { useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
} from '@mui/material'
import { useAppDispatch } from 'hooks/useRedux'
import { useForm } from 'react-hook-form'
import { Permission, RoleInfo } from 'types/user'
import AdministrationRoleService from 'services/AdministrationRoleService'
import styles from './roleForm.module.scss'
import { fetchRoles } from 'store/slices/rolesSlice'
import { showSnackbar } from 'store/slices/snackbarSlice'

interface RoleFormProps {
    isFormOpen: boolean
    handleClose: () => void
    role?: RoleInfo
    permissions: Permission[]
}

interface SubmitFormData {
    id?: number
    title: string
    description?: string
    permissionsMap: boolean[]
}

const RoleForm: React.FC<RoleFormProps> = ({ isFormOpen, handleClose, role, permissions }) => {
    const dispatch = useAppDispatch()

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm<SubmitFormData>({
        defaultValues: {
            id: role?.id,
            title: role?.title || '',
            description: role?.description || '',
        },
    })

    useEffect(() => {
        if (role) {
            reset(role)
        } else {
            reset({
                id: undefined,
                title: '',
                description: '',
                permissionsMap: [],
            })
        }
    }, [reset, role])

    const onSubmit = async (data: SubmitFormData) => {
        try {
            const permissionsData = findTrueIndices(data.permissionsMap)
            const filteredData = {
                title: data.title,
                description: data.description,
                permissions: permissionsData,
            }

            if (role) {
                await AdministrationRoleService.changeRole(role.id, filteredData)
                handleSuccess('Role changed successfully!')
            } else {
                await AdministrationRoleService.createNewRole(filteredData)
                handleSuccess('Role added successfully!')
            }
        } catch (error) {
            handleError(error)
        }
    }

    const handleSuccess = (message: string) => {
        dispatch(fetchRoles())
        dispatch(showSnackbar({ message, severity: 'success' }))
        handleClose()
        reset()
    }

    const handleError = (error: any) => {
        const errorMessage = (error as Error).message
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
        console.error(errorMessage)
    }

    return (
        <>
            <Dialog open={isFormOpen} onClose={handleClose}>
                <DialogTitle className={styles.roleDialogTitle}>{role ? 'Edit Role' : 'Add Role'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.roleForm}>
                            {role && (
                                <div className={styles.fieldForm}>
                                    <TextField
                                        label="Id"
                                        variant="standard"
                                        className={styles.roleFormInput}
                                        placeholder="Id"
                                        autoFocus
                                        {...register('id', { required: 'Required field' })}
                                        error={!!errors.id}
                                        helperText={errors?.id ? errors.id.message : null}
                                    />
                                </div>
                            )}
                            <div className={styles.fieldForm}>
                                <TextField
                                    label="Title"
                                    variant="standard"
                                    className={styles.roleFormInput}
                                    placeholder="Title"
                                    {...register('title', { required: 'Required field' })}
                                    error={!!errors.title}
                                    helperText={errors?.title ? errors.title.message : null}
                                />
                            </div>
                            <div className={`${styles.fieldForm} `}>
                                <TextField
                                    label="Description"
                                    variant="standard"
                                    className={styles.roleFormInput}
                                    placeholder="Description"
                                    {...register('description')}
                                />
                            </div>
                            <div className={styles.permissionsField}>
                                <FormControl component="fieldset" variant="standard">
                                    <FormLabel component="legend">Permissions:</FormLabel>
                                    <FormGroup>
                                        {permissions.map((permission) => (
                                            <FormControlLabel
                                                key={permission.id}
                                                control={
                                                    <Checkbox
                                                        defaultChecked={role?.permissions?.some(
                                                            (currentPermission) =>
                                                                currentPermission.id === permission.id
                                                        )}
                                                        {...register(`permissionsMap.${permission.id}`)}
                                                    />
                                                }
                                                label={permission.title}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit">{role ? 'Apply' : 'Add'}</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RoleForm

function findTrueIndices(arr: boolean[]): number[] {
    return arr.reduce((acc: number[], curr: boolean, index: number) => {
        if (curr) {
            acc.push(index)
        }
        return acc
    }, [])
}
