import React, { FC, useEffect, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'
import { Preloader } from 'components/Preloader/Preloader'
import OnlyWorkingGamesSwitch from 'components/OnlyWorkingGamesSwitch/OnlyWorkingGamesSwitch'
import { ENVIRONMENT } from 'consts/constants'
import useFetchGames from 'hooks/useFetchGames'
import LiveEnvMonitoringTable from './LiveEnvMonitoringTable/LiveEnvMonitoringTable'
import { extractGameInfo, extractGameTimestampAndVersion, filterWorkingGames, gameVersionsEqual } from 'utils/gameUtils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { setCurrentPage } from 'store/slices/currentPageSlice'
import { Page } from 'types/common'
import { toggleLiveMonitoringWorkingGames } from 'store/slices/workingGamesFilterSlice'

import styles from './liveEnvMonitoringPage.module.scss'

const LiveEnvMonitoringPage: FC = () => {
    const dispatch = useAppDispatch()
    const [isLoadingLiveGames, liveGames] = useFetchGames(ENVIRONMENT.LIVE)
    const [isLoadingDevGames, devGames] = useFetchGames(ENVIRONMENT.DEV)
    const { isOnlyWorkingGamesOnLiveMonitoring } = useAppSelector((state) => state.workingGamesFilter)
    const isLoading = isLoadingLiveGames && isLoadingDevGames

    useEffect(() => {
        dispatch(setCurrentPage(Page.LiveEnvMonitoring))
    }, [dispatch])

    const handleStatusGame = (): void => {
        dispatch(toggleLiveMonitoringWorkingGames())
    }

    const liveGamesWithEnvReleaseInfo = useMemo(() => {
        const gamesToDisplay = isOnlyWorkingGamesOnLiveMonitoring ? filterWorkingGames(liveGames) : liveGames

        return gamesToDisplay.map((game) => {
            const gameDevInfo = extractGameInfo(devGames, game.gameClass)
            const gameLiveReleaseInfo = extractGameTimestampAndVersion(game)
            if (gameDevInfo) {
                const gameDevReleaseInfo = extractGameTimestampAndVersion(gameDevInfo)
                const liveAndDevHasEqualVersion = gameVersionsEqual(game, gameDevInfo)
                return { ...game, gameDevReleaseInfo, gameLiveReleaseInfo, liveAndDevHasEqualVersion }
            }
            return { ...game, gameLiveReleaseInfo }
        })
    }, [isOnlyWorkingGamesOnLiveMonitoring, liveGames, devGames])

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className={styles.filterBar}>
                <OnlyWorkingGamesSwitch
                    checked={isOnlyWorkingGamesOnLiveMonitoring}
                    onChange={handleStatusGame}
                    disabled={isLoading}
                />
            </div>
            {isLoading ? <Preloader /> : <LiveEnvMonitoringTable games={liveGamesWithEnvReleaseInfo} />}
        </ErrorBoundary>
    )
}

export default LiveEnvMonitoringPage
