interface GameVersionInfo {
    name: string
    modified: string
    size: number
}

type VersionsObject<T> = {
    [K in keyof T]: T[K]
}

export const extractAndSortGameVersions = <T>(versionsObj: VersionsObject<T>): GameVersionInfo[] => {
    const versions = Object.keys(versionsObj).sort(function (nameA, nameB) {
        // Extract numeric parts and non-numeric parts of the names
        const numericPartA = nameA.match(/\d+/g)
        const numericPartB = nameB.match(/\d+/g)

        // If numeric parts are found, convert them to numbers for comparison
        const versionNumberA = numericPartA ? parseInt(numericPartA[0], 10) : NaN
        const versionNumberB = numericPartB ? parseInt(numericPartB[0], 10) : NaN

        // Compare version numbers first
        if (!isNaN(versionNumberA) && !isNaN(versionNumberB)) {
            if (versionNumberA !== versionNumberB) {
                return versionNumberA - versionNumberB
            }
        }

        // If version numbers are the same or not applicable, compare full names
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1

        return 0
    })

    return versions.map((version) => {
        return {
            name: version,
            modified: versionsObj[version].modified,
            size: bytesToMB(versionsObj[version].size),
        }
    })
}

function bytesToMB(bytes: number): number {
    const MB = 1024 * 1024
    return bytes / MB
}
