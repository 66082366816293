import { useEffect, useState } from 'react'
import { Preloader } from 'components/Preloader/Preloader'
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { fetchTags } from 'store/slices/tagsSlice'
import { showSnackbar } from 'store/slices/snackbarSlice'
import { Tag } from 'types/adminService'
import AdministrationTagService from 'services/AdministrationTagService'
import TagsTable from './tagsTable/TagsTable'
import TagForm from './TagForm/TagForm'

const EditTagsContainer = () => {
    const { tags, isLoading } = useAppSelector((state) => state.tags)
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [selectedTag, setSelectedTag] = useState<Tag>()
    const [isRemoveTagDialogOpen, setIsRemoveTagDialogOpen] = useState(false)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchTags())
    }, [dispatch])

    const handleFormOpen = (tagId?: number): void => {
        const selectedTag = tagId ? tags.find((tag) => tag.id === tagId) : undefined
        setSelectedTag(selectedTag)
        setIsFormOpen(true)
    }

    const handleFormClose = (): void => {
        setIsFormOpen(false)
    }

    const handleRemoveRoleDialogOpen = (tagId: number): void => {
        const selectedTag = tags.find((tag) => tag.id === tagId)
        setSelectedTag(selectedTag)
        setIsRemoveTagDialogOpen(true)
    }

    const handleRemoveTagDialogClose = (): void => {
        setIsRemoveTagDialogOpen(false)
    }

    const removeTag = async (tagId: number): Promise<void> => {
        try {
            await AdministrationTagService.removeTag(tagId)
            dispatch(showSnackbar({ message: 'Tag removed successfully', severity: 'success' }))
            setIsRemoveTagDialogOpen(false)
            dispatch(fetchTags())
        } catch (error) {
            const errorMessage = (error as Error).message
            dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
            console.error(errorMessage)
        }
    }

    return (
        <>
            {isLoading ? (
                <Preloader />
            ) : (
                <>
                    <TagsTable
                        tags={tags}
                        handleFormOpen={handleFormOpen}
                        handleRemoveTagDialogOpen={handleRemoveRoleDialogOpen}
                    />
                    <TagForm tag={selectedTag} isFormOpen={isFormOpen} handleClose={handleFormClose} />
                </>
            )}
            {selectedTag && (
                <ConfirmationDialog
                    isOpen={isRemoveTagDialogOpen}
                    title="Remove tag"
                    onConfirm={removeTag.bind(undefined, selectedTag.id)}
                    onClose={handleRemoveTagDialogClose}
                    description={`Are you sure you want to remove  '${selectedTag?.title}' tag`}
                />
            )}
        </>
    )
}

export default EditTagsContainer
