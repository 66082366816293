import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import AdministrationGameService from 'services/AdministrationGameService'
import { GameInfo } from 'types/adminService'
import EditGamesMetadata from './EditGamesMetadata'
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'

const EditGamesMetadataContainer = () => {
    const [gamesInfo, setGamesInfo] = useState<GameInfo[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchGameMetadata = async () => {
            try {
                const response = await AdministrationGameService.getGamesInfo()
                setGamesInfo(response.data.games)
            } catch (error) {
                console.error((error as Error).message)
            } finally {
                setIsLoading(false)
            }
        }

        fetchGameMetadata()
    }, [])

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <EditGamesMetadata gamesInfo={gamesInfo} isLoading={isLoading} />
        </ErrorBoundary>
    )
}

export default EditGamesMetadataContainer
