import { ChangeEvent } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

interface SearchProps {
    searchedGameName: string
    setSearchedGameName: (name: string) => void
}

export const Search: React.FC<SearchProps> = ({ searchedGameName, setSearchedGameName }) => {
    const updateValueSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchedGameName(e.target.value)
    }

    return (
        <TextField
            label="Search game"
            value={searchedGameName}
            onChange={updateValueSearch}
            variant="standard"
            fullWidth
            sx={{
                mb: '1.5rem',
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    )
}
