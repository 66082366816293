import { Preloader } from 'components/Preloader/Preloader'
import GamesMetadataTable from './gamesMetadataTable/GamesMetadataTable'
import { GameInfo } from 'types/adminService'

interface EditGamesMetadataProps {
    gamesInfo: GameInfo[]
    isLoading: boolean
}

const EditGamesMetadata: React.FC<EditGamesMetadataProps> = ({ gamesInfo, isLoading }) => {
    return isLoading ? <Preloader /> : <GamesMetadataTable gamesInfo={gamesInfo} />
}

export default EditGamesMetadata
