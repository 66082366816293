import { styled, TableCell, tableCellClasses, TextField, InputAdornment, Button } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import { useState } from 'react'
import { GameInfo } from 'types/adminService'
import { validateGameMetadata } from 'utils/validation'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

interface GameMetadataTableRowProps {
    game: GameInfo
    gameMetadata: string
    setGameMetadata: React.Dispatch<React.SetStateAction<string>>
    handleSaveGameMetadata: (metadata: string) => void
}

const GameMetadataTableRow: React.FC<GameMetadataTableRowProps> = ({
    game,
    gameMetadata,
    setGameMetadata,
    handleSaveGameMetadata,
}) => {
    const [isJsonValid, setIsJsonValid] = useState(true)
    const [helperText, setHelperText] = useState('')

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value
        try {
            const parsedData = JSON.parse(value)
            const validationResult = validateGameMetadata(parsedData)

            if (validationResult.success) {
                setHelperText('')
                setIsJsonValid(true)
            } else {
                setIsJsonValid(false)
                const { path, message } = validationResult.error.errors[0]
                setHelperText(`${path} ${message}`)
            }
        } catch {
            setIsJsonValid(false)
            setHelperText('Invalid JSON format')
        }
        setGameMetadata(value)
    }

    const handleSaveButtonClick = (): void => {
        handleSaveGameMetadata(gameMetadata)
    }

    return (
        <StyledTableRow>
            <StyledTableCell align="center" component="th" scope="row">
                {game.gameUID}
            </StyledTableCell>
            <StyledTableCell align="center">{game.gameName}</StyledTableCell>
            <StyledTableCell align="center">{game.gameClass}</StyledTableCell>
            <StyledTableCell>
                <TextField
                    value={gameMetadata}
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    minRows={2}
                    error={!isJsonValid}
                    helperText={helperText}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    onClick={handleSaveButtonClick}
                                    variant="contained"
                                    size="small"
                                    disabled={!isJsonValid || gameMetadata.length === 0}
                                >
                                    Save
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                />
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default GameMetadataTableRow
