import { GameInfo } from 'types/adminService'
import GameMetadataTableRow from './GameMetadataTableRow'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'hooks/useRedux'
import AdministrationGameService from 'services/AdministrationGameService'
import { showSnackbar } from 'store/slices/snackbarSlice'

interface GameTagsTableRowContainerProps {
    game: GameInfo
}

const GameMetadataTableRowContainer: React.FC<GameTagsTableRowContainerProps> = ({ game }) => {
    const [gameMetadata, setGameMetadata] = useState<string>('')
    const dispatch = useAppDispatch()

    useEffect(() => {
        ;(async function fetchGameMetadata() {
            try {
                const response = await AdministrationGameService.getGameMetadata(game.id)
                const { metadata } = response.data
                setGameMetadata(metadata ?? '')
            } catch (error) {
                console.error('Failed to fetch game metadata:', (error as Error).message)
            }
        })()
    }, [game])

    const handleSaveGameMetadata = async (metadata: string) => {
        try {
            await AdministrationGameService.updateGameMetadata(game.id, metadata)
            setGameMetadata(metadata)
            dispatch(showSnackbar({ severity: 'success', message: 'Metadata save successfully' }))
        } catch (error) {
            dispatch(showSnackbar({ severity: 'error', message: (error as Error).message }))
            console.error('Failed to save game metadata:', (error as Error).message)
        }
    }

    return (
        <GameMetadataTableRow
            game={game}
            gameMetadata={gameMetadata}
            setGameMetadata={setGameMetadata}
            handleSaveGameMetadata={handleSaveGameMetadata}
        />
    )
}

export default GameMetadataTableRowContainer
