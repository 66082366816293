import { Grid, SelectChangeEvent } from '@mui/material'
import { Game as GameInterface, RunGameParameters } from 'types/gameService'
import GameCardSimpleMode from './GameCardSimpleMode/GameCardSimpleMode'
import GameCardWithControlPanel from './gameCardWithControlPanel/GameCardWithControlPanel'

interface GameProps {
    game: GameInterface
    runGame: (gameParams: RunGameParameters) => void
    service: string
    selectedLanguage: string
    handleChangeLanguage: (event: SelectChangeEvent<string>) => void
    selectedCurrency: string
    handleChangeCurrency: (event: SelectChangeEvent<string>) => void
    version: string
    handleChangeVersionGame: (event: SelectChangeEvent<string>) => void
    imagePath: string | undefined
    handleResetGameState: (gameMode: string) => Promise<void>
    isSimpleLaunchGameMode: boolean
}

export const Game: React.FC<GameProps> = (props) => {
    const {
        game,
        runGame,
        service,
        selectedLanguage,
        handleChangeLanguage,
        selectedCurrency,
        handleChangeCurrency,
        version,
        handleChangeVersionGame,
        imagePath,
        handleResetGameState,
        isSimpleLaunchGameMode,
    } = props

    return (
        <Grid item xs={12} sm={6} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            {isSimpleLaunchGameMode ? (
                <GameCardSimpleMode
                    game={game}
                    imagePath={imagePath}
                    runGame={runGame}
                    selectedLanguage={selectedLanguage}
                />
            ) : (
                <GameCardWithControlPanel
                    game={game}
                    runGame={runGame}
                    service={service}
                    selectedLanguage={selectedLanguage}
                    handleChangeLanguage={handleChangeLanguage}
                    selectedCurrency={selectedCurrency}
                    handleChangeCurrency={handleChangeCurrency}
                    version={version}
                    handleChangeVersionGame={handleChangeVersionGame}
                    handleResetGameState={handleResetGameState}
                    imagePath={imagePath}
                />
            )}
        </Grid>
    )
}
