import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlertColor } from '@mui/material'

export interface SnackbarState {
    open: boolean
    message: string
    severity: AlertColor
}

const initialState: SnackbarState = {
    open: false,
    message: '',
    severity: 'info',
}

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        showSnackbar: (state, { payload }: PayloadAction<{ message: string; severity: AlertColor }>) => {
            state.open = true
            state.message = payload.message
            state.severity = payload.severity
        },
        hideSnackbar: (state) => {
            state.open = false
        },
    },
})

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions
export default snackbarSlice.reducer
