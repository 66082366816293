import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import GameService from 'services/GameService'
import { IntegrationBackend } from 'types/common'
import { LegacyBackendGameURLParams, OSIBackendOpenGameURLParams } from 'types/gameService'

export interface LegacyBackendGameParams {
    integrationBackend: IntegrationBackend.Legacy
    urlParams: LegacyBackendGameURLParams
}

export interface OSIBackendGameParams {
    integrationBackend: IntegrationBackend.OSI
    urlParams: OSIBackendOpenGameURLParams
}

interface CurrentGameState {
    URL: string
    error: string | null | undefined
}

const initialState: CurrentGameState = {
    URL: '',
    error: null,
}

export const fetchGameURL = createAsyncThunk<
    string,
    LegacyBackendGameParams | OSIBackendGameParams,
    { rejectValue: string }
>('currentGame/fetchGameURL', async ({ integrationBackend, urlParams }, { rejectWithValue, dispatch }) => {
    try {
        const response =
            integrationBackend === IntegrationBackend.Legacy
                ? await GameService.getLegacyGameURL(urlParams)
                : await GameService.getOSIGameURL(urlParams)
        const URL = response.data.url
        dispatch(setCurrentGameURL(URL))
        return URL
    } catch (error: any) {
        return rejectWithValue(error.message)
    }
})

const currentGameSlice = createSlice({
    name: 'currentGame',
    initialState,
    reducers: {
        setCurrentGameURL(state, action: PayloadAction<string>) {
            state.URL = action.payload
        },
        clearCurrentGameError(state) {
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGameURL.pending, (state) => {
                state.error = null
            })
            .addCase(fetchGameURL.rejected, (state, action) => {
                state.error = action.payload
            })
    },
})

export const { setCurrentGameURL, clearCurrentGameError } = currentGameSlice.actions

export default currentGameSlice.reducer
