import {
    styled,
    tableCellClasses,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'
import styles from './gamesMetadataTable.module.scss'
import { GameInfo } from 'types/adminService'
import GameMetadataTableRowContainer from './TableRow/GameMetadataTableRowContainer'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

interface GameTagsTableProps {
    gamesInfo: GameInfo[]
}

const GamesMetadataTable: React.FC<GameTagsTableProps> = ({ gamesInfo }) => {
    return (
        <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className={styles.tableHead}>
                    <TableRow>
                        <StyledTableCell align="center">gameUID</StyledTableCell>
                        <StyledTableCell align="center">gameName</StyledTableCell>
                        <StyledTableCell align="center">gameClass</StyledTableCell>
                        <StyledTableCell align="center">metadata</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gamesInfo.map((gameInfo) => (
                        <GameMetadataTableRowContainer key={gameInfo.id} game={gameInfo} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default GamesMetadataTable
